<template>
    <div id="SelectLeague">
        <div class="SelectLeague_content">
            <div class="title">亿动赛事管理平台</div>
            <div class="list">
                <ul>
                    <li v-for="(item,index) in list" :key="index" :class="{'active':activeIndex == index}" @click="select(index)">
                        <div class="league_logo">
                            <img v-if="item.league_logo" :src="item.league_logo">
                            <span v-else>空</span>
                        </div>
                        <div class="league_name">{{item.league_name}}</div>
                    </li>
                </ul>
            </div>
            <el-row type="flex" justify="center" align="middle" style="margin-bottom:0;margin-top:20px" v-if="list.length">
                <div v-show="activeIndex > -1">已选择：{{activeIndex > -1 && list[activeIndex].league_name}}</div>
            </el-row>
            <el-row type="flex" justify="center" style="margin-top:20px" v-if="list.length">
                <el-button type="info" @click="$router.back()" v-show="type == 1">返回</el-button>
                <el-button type="primary" @click="confirm" style="width:200px;">确认</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import { post } from '@/api/oldApi'
import { GetMainLeagueMastersInfo } from '@/api'
export default {
    data(){
        return{
            type: this.$route.query.type || 0,
            activeIndex: -1,
            list: []
        }
    },
    created(){
        post('Match/Entrance', {
            action: 'Getleague_users',
            user_token: ""
        }).then((data)=>{
            if(data.Code == 0){
                this.list = data.league_users_List;
                if(this.type != 1 && data.league_users_List.length == 1){
                    this.activeIndex = 0;
                    this.confirm();
                }
            }
        })
    },
    methods:{
        select(index){
            if(this.activeIndex == index){
                this.activeIndex = -1;
            }else{
                this.activeIndex = index;
            }
        },
        confirm(){
            if(this.activeIndex == -1){
                this.$message.warning('请选择你要操作的赛事');
            }else{
                let obj = this.list[this.activeIndex];
                this.$store.commit('removeCardsMastersInfo');
                GetMainLeagueMastersInfo({
                    MainLeagueId: obj.league_id
                }).then(data=>{
                    if(data.Code == 0){
                        this.$store.commit('setCardsMastersInfo', data.CardsMasters);
                    }
                })
                this.$store.commit('setLeagueInfo',{
                    id: obj.league_id,
                    name: obj.league_name,
                    logo: obj.league_logo
                });
                if(this.type == 1){
                    this.$message.success('已切换'+obj.league_name);
                }
                this.$router.replace('/');
            }
        }
    }
}
</script>
<style scoped>
#SelectLeague{
    width: 100%;
    height: 100%;
    background-color: #101c35;
    background-image: url('~@/assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title{
    text-align: center;
    line-height: 36px;
    font-size: 24px;
    margin-bottom: 20px;
}
.SelectLeague_content{
    width: 450px;
    height: auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: -100px;
}
.list{
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
}
.list li{
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px rgba(0,0,0,0) solid;
    cursor: pointer;
}
.list li.active{
    border: 2px #009aff solid;
}
.list li .league_logo{
    width: 50px;
    height: 50px;
    border: 1px #eeeeee solid;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bbbbbb;
}
.list li .league_logo img{
    width: auto;
    height: 100%;
}
.league_name{
    width: calc(100% - 60px);
    word-wrap: break-word;
}
</style>